import React from 'react';
import Fade from 'react-reveal/Fade';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const DevOpsList = () => {
  const imgData = useStaticQuery(graphql`
    query {
      contentfulAsset(title: {eq: "services-devops-list"}) {
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
    }
  `);
  const image = imgData.contentfulAsset.fluid;

  return (
    <section className="sec_pad">
      <div className="container">
        <div className="row flex-row-reverse">
          <div className="col-lg-6">
            <div className="seo_features_img h-100">
              <div className="round_circle" />
              <div className="round_circle two" />
              <Img
                fluid={image}
                className="h-100"
                imgStyle={{
                  margin: '0 auto',
                  width: '75%',
                  height: 'auto',
                  right: 0,
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <Fade bottom cascade>
              <div className="seo_features_content">
                <h2>Modern Apps Require Modern Infrastructure</h2>
                <ul>
                  <li>Containerization</li>
                  <li>Build, Test, Deploy pipelines</li>
                  <li>Best practices and organizational structure</li>
                  <li>Automated Testing</li>
                  <li>Monitoring and Alerting</li>
                  <li>Metric and Analysis</li>
                  <li>Faster Builds, Tests and Deploys</li>
                  <li>Infrastructure Provisioning</li>
                  <li>Consistent deploys between heterogenous data centers</li>
                </ul>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DevOpsList;
