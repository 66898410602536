import React from 'react';
import Info from '../../components/Service/DevOps/ServiceInfo';
import List from '../../components/Service/DevOps/ServiceList';

const DevOps = () => (
  <div>
    <Info />
    <List />
  </div>
);
export default DevOps;
