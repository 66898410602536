import React from 'react';
import Reveal from 'react-reveal';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import urls from '../../../data/urls';

const AppFeatures = () => {
  const imgData = useStaticQuery(graphql`
    query {
      contentfulAsset(title: {eq: "services-devops-info"}) {
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
    }
  `);
  const image = imgData.contentfulAsset.fluid;

  return (
    <section className="sec_pad">
      <div className="triangle_shape" />
      <div className="container">
        <div className="row">
          <div className="col-lg-5 d-flex align-items-center">
            <Img fluid={image} className="w-75 mx-auto"/>
          </div>
          <div className="col-lg-7 d-flex align-items-center">
            <Reveal bottom cascade>
              <div className="app_featured_content">
                <h2 className="f_p f_size_30 f_700 t_color3 l_height45 mb-30">
                  Lay a Strong Foundation
                  <br />
                  On-Premise and Cloud-Based Infrastructure
                </h2>
                <Link to={urls.contactUs} className="app_btn btn_hover my-4">Get a Quote</Link>
                <p className="f_400">
                  Building new infrastructure from scratch or contemplating a complicated database migration? OPNA’s technical architects can help guide the process from vendor selection to hardware configuration and platform integration.
                  <br />
                  <br/>
                  We’ve led projects of all sizes: from nascent companies on their first build to global corporations needing innovative solutions to their ever growing data demands.
                  <br />
                  <br/>
                  We always provide ample documentation for ongoing ease of maintenance and training. Faster builds, tests, and deployments mean you can expect to fix production bugs with speed and grace.
                </p>
              </div>
            </Reveal>

          </div>
        </div>
      </div>
    </section>
  );
}

export default AppFeatures;
